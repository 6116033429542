import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    title: {
        margin: theme.spacing(2, 0, 1),
    },
    smalltitle: {
        fontSize: 14,
    },
    subtitle: {
        margin: theme.spacing(1, 0, 2),
        fontSize: 12,
    },
}))
