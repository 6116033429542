import i18next from 'i18next'
import moment from 'moment'
import 'moment/locale/fr'

import config from 'config'
import fr from 'lang/fr'
// import en from 'translation/en'

/* Language data & i18n settings */
export const defaultLanguage = "fr"

// get code from first param in url if it matches
const urlParams = window.location.pathname.split('/')
const regex = /^(fr|en)$/g
const matches = urlParams[1].match(regex)
export const activeLanguage = matches ? matches[0] : defaultLanguage

export const languages = { fr } // { fr, en }

export const options = {
    // fallbackLng: defaultLanguage,
    debug: config.debugMode,
    resources: languages,
}

/* Functions */

function init(lang) {
    window.i18next = i18next
    window.moment = moment

    //i18next.use(i18nextBrowserLanguageDetector)
    window.i18next.on('languageChanged', () => langChanged())
    window.i18next.init(options, () => changeLang(lang))
}


function changeLang(lang) {
    if (lang !== window.i18next.language) {
        window.i18next.changeLanguage(lang)
        window.moment.locale(lang)
    } else if (options.debug) {
        console.log('Tried changing language but it is already set to ' + lang)
    }
}

function langChanged() {
    if (options.debug) {
        // console.log(window.i18next.language)
    }
}

export const getLang = () => {
    return window.i18next.language || activeLanguage
}

export const _ = (keys, options) => i18next.t(keys, options)

export const exists = (keys, options) => i18next.exists(keys, options)

export const Lang = {
    activeLanguage,
    defaultLanguage,
    languages,
    options,
    changeLang,
    getLang,
    exists,
    _,
}

init(activeLanguage)
