import { httpClient, save } from 'helpers'

export const categoryService = {
    list,
    show,
    create,
    update,
    destroy,
}

const resource = 'category'

function list() {
    return httpClient.GET(resource, {}, [ save('categories') ])
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(category) {
    const data = JSON.stringify(category)
    return httpClient.POST(resource, { data })
}

function update(category) {
    const data = JSON.stringify(category)
    return httpClient.PUT(`${resource}/${category.id}`, { data })
}

function destroy(category) {
    return httpClient.DELETE(`${resource}/${category.id}`)
}
