import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import {
    _,
    browserUrls,
    authenticated } from 'helpers'

import { alertActions } from 'modules'

const propTypes = {
    redirect: PropTypes.bool,
    redirectPath: PropTypes.string,
    message: PropTypes.string,
}

const defaultProps = {
    redirect: false,
    redirectPath: browserUrls.login,
    message: _('alert.401'),
}

// Redirects the user if he's not authenticated
class PrivateRoute extends Component {

    updated = false

    // refresh the auth and fetch the full user data
    render() {
        const {
            redirect,
            redirectPath,
            component: Component,
            ...rest} = this.props

        if (!authenticated()) {
            if (redirect) {
                this.props.authAlert()
                return <Redirect to={redirectPath} />
            }
            return null
        }
        
        return  <Route {...rest} render={props => <Component {...props} /> } />
    }
}

PrivateRoute.propTypes = propTypes
PrivateRoute.defaultProps = defaultProps

function mapStateToProps({ auth, user }) {
    return { auth, user }
}

const mapDispatchToProps = { 
    authAlert: alertActions.auth,
}

const connectedPrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
export { connectedPrivateRoute as PrivateRoute }
