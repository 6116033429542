import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

import { useStyles } from './Modal.styles'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Modal = ({ open, handleClose, children, title }) => {
    const classes = useStyles()

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar variant="dense">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography className={classes.title} noWrap>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>{children}</div>
        </Dialog>
    )
}

export default Modal
