import React from 'react'
import flattenDeep from 'lodash/flattenDeep'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import { history } from 'helpers'
import { alertActions } from 'modules'
import Button from 'components/Button'

import { variantIcon, useStyles } from './Alert.styles'

const Content = (props) => {
    const classes = useStyles()
    const { className, message, onClose, variant, buttons, ...other } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                ...buttons,
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    )
}

Content.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
        .isRequired,
}

class Alert extends React.Component {

    mounted = false
    state = {
        message: '',
        variant: 'info',
        open: false,
    }

    componentDidMount() {
        this.mounted = true
        this.init()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidUpdate() {
        this.init()
    }

    init() {
        const { alerts } = this.props

        // display the last alert in the array (the one we just received)
        if (alerts.length) {
            const key = alerts.length - 1
            const alert = alerts[key]

            const messages = this.makeMessages(alert)
        
            if (!this.mounted) return
            
            const makeButton = () => {
                this.setState({
                    open: true,
                    message: messages[0],
                    options: alert.options,
                    variant: alert.type,
                })
            }
            
            // if snackbar already visible, close it and wait before showing the new one
            if (this.state.open) {
                this.setState({ open: false }, () => setTimeout(makeButton, 200))
            } else {
                makeButton()
            }
            
            // clear the alerts stack
            this.props.clear()
        }
    }

    makeMessages = alert => {
        if (!alert) return ''
        let { message } = alert

        message = message.text ? message.text : message 

        // return message in array
        if (typeof message === 'string') {
            return [message]
        } else if (Array.isArray(message)) {
            return flattenDeep(message)
        }

        let objResult = flattenDeep(Object.values(message))
        return objResult
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({
            open: false,
        })
    }

    render() {
        const { open, message, variant, options } = this.state

        // make buttons array from options
        const buttons = options ? options.map((option, key) => {
            return <Button
                key={key}
                onClick={() => {
                    history.push(option.url)
                    this.handleClose()
                }}
                {...option}
            />
        }) : []

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={this.handleClose}
            >
                <Content
                    onClose={this.handleClose}
                    variant={variant}
                    message={message}
                    buttons={buttons}
                />
            </Snackbar>
        )
    }
}

const mapStateToProps = ({ alerts }) => {
    return { alerts }
}

const mapDispatchToProps = { ...alertActions }

export default connect(mapStateToProps, mapDispatchToProps)(Alert)
