import { httpClient, save } from 'helpers'

export const eventService = {
    list,
    show,
    create,
    update,
    destroy,
}

const resource = 'event'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''), {}, [save('events')])
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(event) {
    const data = JSON.stringify(event)
    return httpClient.POST(resource, { data })
}

function update(event) {
    const data = JSON.stringify(event)
    return httpClient.PUT(`${resource}/${event.id}`, { data })
}

function destroy(event) {
    return httpClient.DELETE(`${resource}/${event.id}`)
}
