import moment from 'moment'

import { Lang } from 'helpers'
import fr from 'lang/fr/date'
// import en from 'lang/en/date'

// settings used for localised formatting & date picker
// export const translations = { fr }
export const translation = fr //translations[Lang.getLang()]
export const apiDateFormat = "YYYY-MM-DD"

/* 
 *
 * Date Functions
 * 
 */

export function formatForAPI(date) {
    return moment(date).format(apiDateFormat)
}

export function displayDuration(startString, endString) {
    const start = moment(startString)
    const end = moment(endString)
   
    if (start.isValid() && end.isValid()) {
        const days = end.diff(start, 'days') + 1
        return Lang._('expedition.duration', { count: days})
    }

    return undefined
}

export function displayDateRange(startString, endString = '') {
    const start = moment(startString)
    const end = moment(endString)
    const year = (end.isValid() && !end.isSame(moment(), 'year') ? end.format(translation.formats.year) : '')

    // date range
    if (end.isValid() && !start.isSame(end)) {

        // add month in first date if it's different
        if (!start.isSame(end, 'month')) {
            return start.format(translation.formats.fromM) + end.format(translation.formats.toM) + year
        }

        return start.format(translation.formats.from) + end.format(translation.formats.to) + year
    }

    // single date
    return start.format(translation.format) + year
}

export function displayDate(string) {
    const date = moment(string)

    if (date.isValid()) {
        return date.format(translation.formats.standard)
    }
}

export function displayHumanFutureDate(string) {
    const today = new moment()
    const date = moment(string).endOf('day')
    console.log(today, date)
    if (date.isValid()) {
        return moment.duration(today.diff(date)).humanize()
    }
}