import React from 'react'
import { connect } from 'react-redux'
import Markdown from 'react-markdown'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'

import { MediaCard } from 'components/Card'
import Pageheader from 'components/Pageheader'
import { ButtonList, BackButton, Button } from 'components/Button'

import { _ } from 'helpers'
import { displayDate, formatPhone } from 'utils'
import { postActions, getCategoryById } from 'modules'

import { useStyles } from './PostShow.styles'

const Logo = ({ post }) => {
    const classes = useStyles()
    if (!post || !post.company || !post.company.logo) return null

    const { logo: { url }, name } = post.company

    return (
        <div className={classes.logobar}>
            <img
                src={url}
                alt={name}
                className={classes.logo}
            />
        </div>
    )
}

const Header = ({ post, categories }) => {
    const classes = useStyles()
    if (!post) return null

    const count = post.count > 0 ? _('app.posts', { count: post.count }) : false
    const title = post.name
    const smalltitle = post.company
        ? `${post.company.name}${post.company.address && `, ${post.company.address}`}`
        : false
    const date = displayDate(post.updated_at)
    const subtitle = `${date} ${post.postType ? `· ${post.postType}` : ''} ${count ? `· ${count}` : ''}`

    return (
        <div className={classes.header}>
            {post.categories &&
                post.categories.map(id => {
                    const category = getCategoryById(categories, id)
                    return <Icon key={id} className={classes.icon} color="primary">{category.icon}</Icon>
                })
            }
            <Pageheader
                title={title}
                smalltitle={smalltitle}
                subtitle={subtitle}
            />
            <hr className={classes.hr} />
        </div>
    )
}

const Gallery = ({ post }) => {
    const classes = useStyles()
    if (!post || !post.medias || post.medias.length === 0) return null
    
    return  (
        <>
            <hr className={classes.hr} />
            <Grid container spacing={2}>
                {post.medias.map((image, key) => (
                    <Grid key={key} xs={12} sm={6} md={4} item>
                        <MediaCard
                            url={image.url}
                            title={image.name}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

const Text = ({ post }) => {
    const classes = useStyles()
    if (!post) return null
    
    return  (
        <>
            <Typography className={classes.title}>Description</Typography>
            {post.text &&
                <>
                    <Typography className={classes.subtitle}>{_('app.tasks')}</Typography>
                    <Markdown source={post.text} />
                </>
            }
            {post.text2 &&
                <>
                    <Typography className={classes.subtitle}>{_('app.req')}</Typography>
                    <Markdown source={post.text2} />
                </>
            }
            {post.text3 &&
                <>
                    <Typography className={classes.subtitle}>{_('app.info')}</Typography>
                    <Markdown source={post.text3} />
                </>
            }
        </>
    )
}

const ListItemLink = props => <ListItem button component="a" {...props} />

const ContactList = ({ post }) => {
    const phone = post.company.phone && `tel:${formatPhone(post.company.phone)}`
    const email = post.company.email && `mailto:${post.company.email}`
    return (
        <List>
            {phone && <ListItemLink href={phone}>
                <ListItemIcon><PhoneIcon color="primary" /></ListItemIcon>
                <ListItemText primary={post.company.phone} />
            </ListItemLink>}

            {email && <ListItemLink href={email}>
                <ListItemIcon><EmailIcon color="primary" /></ListItemIcon>
                <ListItemText primary={post.company.email} />
            </ListItemLink>}
        </List>
    )
}

class PostShow extends React.Component {

    state = {
        showContact: false,
    }

    componentDidMount() {
        this.props.showPost(this.props.post_id)
    }

    render() {
        const { post, categories } = this.props
        const { showContact } = this.state
        if (!post) return null

        const noContact = !post.company || (!post.company.contact_name && !post.company.phone && !post.company.email)

        const handleClick = () => {
            this.setState({ showContact: !showContact })
        }

        return (
            <div className="postpage">
                <Logo post={post} />
                <Header post={post} categories={categories} />

                {!showContact && (<>
                    <Text post={post} />
                    <Gallery post={post} />
                </>)}

                {showContact && (<>
                    {post.company.contact_name && <Typography variant="body1">{_('app.contact', { value: post.company.contact_name })}</Typography>}
                    <ContactList post={post} />
                </>)}
               
                <ButtonList>
                    {!noContact && <Button
                        label={showContact ? _('app.viewPost') : _('app.apply')}
                        onClick={handleClick}
                    />}
                    <BackButton />
                </ButtonList>
            </div>
        )
    }
}

function mapStateToProps(state, { match }) {
    const { posts, categories } = state
    const { post } = match.params
    const post_id = post.split('-')[0]
    return {
        post_id,
        post: posts[post_id],
        categories,
    }
}

const mapDispatchToProps = {
    showPost: postActions.show,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostShow)
