import React from 'react'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './Pageheader.styles'

const Pageheader = ({ title, smalltitle, subtitle }) => {
    const classes = useStyles()
    return (
        <>
            <Typography variant="h5" component="h1" className={classes.title}>{title}</Typography>
            <Typography variant="h6" component="h2" className={classes.smalltitle} color="textSecondary">{smalltitle}</Typography>
            <Typography variant="h6" className={classes.subtitle} color="textSecondary">{subtitle}</Typography>
        </>
    )
}

export default Pageheader
