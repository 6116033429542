import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    header: {
        height: '75px',
        margin: theme.spacing(3, 0),
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            height: '100px',
            margin: theme.spacing(3, 0),
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(5, 0),
        },
        [theme.breakpoints.up('xl')]: {
            margin: theme.spacing(8, 0),
        },
    },
    logo: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
}))
