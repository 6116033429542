import { makeStyles } from '@material-ui/core/styles'

import bg from 'images/bg.jpg'

export const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.grey[900],
        },
        strong: {
            fontWeight: 'bold',
        },
    },
    content: {
        padding: theme.spacing(8, 2, 12),
        minHeight: '100vh',
        position: 'relative',
        boxSizing: 'border-box',
    },
    contentbg: {
        [theme.breakpoints.up('md')]: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            position: 'absolute',
            height: '100%',
            backgroundImage: `url('${bg}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            opacity: '0.2',
            zIndex: '-1',
        },
    },
    logobar: {
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        zIndex: 10,
    },
    logo: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
}))
