import mapKeys from 'lodash/mapKeys'
import { categoryConstants } from 'modules' // _constants

const storedData = JSON.parse(localStorage.getItem('categories'))
const initialState = storedData ? { ...mapKeys(storedData, 'id') } : {}

// all state changes manipulations happen here
export const category = function(state = initialState, { type, payload }) {
    switch (type) {
        case categoryConstants.LIST_SUCCESS:
            return mapKeys(payload.data, 'id')

        case categoryConstants.SHOW_SUCCESS:
            // adds the item with id has key in the items list state
            // using an object as the items list allows to overwrite the item with a newly fetched version of itself
            // whereas using an array would only push a duplicate version
            return { ...state, [payload.id]: payload }

        case categoryConstants.CREATE_SUCCESS:
        case categoryConstants.UPDATE_SUCCESS:
            return {
                ...state,
                [payload.data.id]: payload.data,
            }

        case categoryConstants.DELETE_SUCCESS:
            // returns a copy of the state without the deleted item
            let newState = { ...state }
            delete newState[payload.id]
            return newState

        default:
            return state
    }
}
