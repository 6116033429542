
import React from 'react'
import BaseCard from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import CardHeader from '@material-ui/core/CardHeader'
import Icon from '@material-ui/core/Icon'

import { _, history, authenticated } from 'helpers'
import Modal from 'components/Modal'

import { useStyles, useInvertedStyles } from './Card.styles'

const Card = props => {
    const { url, title, subtitle, date } = props
    const classes = useStyles()
    return (
        <BaseCard className={classes.root} onClick={() => history.push(url)}>
            <CardActionArea className={classes.action}>
                <CardContent>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.subtitle} color="textSecondary">{subtitle}</Typography>
                    <Typography
                        className={classes.date}
                        color="textSecondary"
                        component="p"
                    >
                        {date}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </BaseCard>
    )
}


export const CategoryCard = props => {
    const { title, subheader, icon, url, alert } = props
    const classes = useStyles()

    const handleClick = () => {
        if (!authenticated()) {
            alert()
        } else {
            history.push(url)
        }
    }

    return (
        <BaseCard className={classes.root} onClick={handleClick}>
            <CardActionArea className={classes.action}>
                <CardHeader
                    classes={{
                        title: classes.title,
                        subheader: classes.subheader,
                    }}
                    avatar={<Icon color="primary">{icon}</Icon>}
                    title={title}
                    subheader={subheader}
                />
            </CardActionArea>
        </BaseCard>
    )
}


export const EventCategoryCard = props => {
    const { alert } = props
    const classes = useInvertedStyles()

    const handleClick = () => {
        if (!authenticated()) {
            alert()
        } else {
            history.push('/event')
        }
    }

    return (
        <BaseCard className={classes.root} onClick={handleClick}>
            <CardActionArea className={classes.action}>
                <CardHeader
                    classes={{
                        title: classes.title,
                        subheader: classes.subheader,
                    }}
                    avatar={<Icon>event</Icon>}
                    title={_('app.events')}
                />
            </CardActionArea>
        </BaseCard>
    )
}


export const MediaCard = props => {
    const { url, title } = props
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)

    function handleClickOpen() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }
    return (
        <BaseCard className={classes.card}>
            <CardActionArea onClick={handleClickOpen}>
                <CardMedia
                    className={classes.media}
                    image={url}
                    title={title}
                />
            </CardActionArea>
            <Modal open={open} handleClose={handleClose} title={title} >
                <img src={url} alt={title} className={classes.fullimage} />
            </Modal>
        </BaseCard>
    )
}

export default Card
