import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import Routes from 'app/Routes'
import Alert from 'components/Alert'
import Topbar from 'components/Topbar'
import Bottombar from 'components/Bottombar'

import { useStyles } from './App.styles'

import {
    postActions,
    eventActions,
    categoryActions,
    optionActions,
    alertActions } from 'modules'
import { history, authenticated } from 'helpers'

import { theme } from 'utils'

const Content = ({ children }) => {
    const classes = useStyles()
    return <div className={classes.content}>
        <div className={classes.contentbg} />
        <Grid container justify="center">
            <Grid xs={12} md={10} lg={8} xl={6} item>{children}</Grid>
        </Grid>
    </div>
}

class App extends React.Component {

    componentDidMount() {
        this.fetchData(true)
    }

    componentWillMount() {  
        history.listen((location, action) => {
            // on location change
            window.scrollTo(0, 0)
            this.fetchData()
        })
    }

    fetchData(forceUpdate = false) {

        const shouldUpdate = forceUpdate ? true : this.checkDate()
        
        if (!shouldUpdate) return

        this.props.listOption()
        this.props.listCategory()

        if(!authenticated()) return
        
        this.props.listPost()
        this.props.listEvent()
    }

    checkDate() {
        const now = Math.floor(new Date().getTime() / 1000)
        const updated_at = parseInt(localStorage.getItem('updated_at'))
        const target = updated_at + 60

        // oudated
        if (isNaN(updated_at) || now > target) {
            localStorage.setItem('updated_at', now)
            return true
        }

        return false        
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />              
                <Topbar />
                <Content>
                    <Routes />
                </Content>
                <Bottombar alert={this.props.authAlert} options={this.props.options} /> 
                <Alert />              
            </ThemeProvider>
        )
    }
}

function mapStateToProps(state) {
    const { categories, posts, events, options } = state
    return {
        categories,
        posts,
        events,
        options,
    }
}

const mapDispatchToProps = {
    listOption: optionActions.list,
    listPost: postActions.list,
    listEvent: eventActions.list,
    listCategory: categoryActions.list,
    authAlert: alertActions.auth,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
