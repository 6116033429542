import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    header: {
        margin: theme.spacing(3, 0),
    },
    hr: {
        margin: theme.spacing(3, 0),
        border: 'none',
        borderBottom: '1px solid #fff',
        opacity: 0.3,
    },
    title: {
        fontWeight: 'bold',
    },
    subtitle: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
        fontSize: '14px',
    },
}))