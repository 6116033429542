import React from 'react'
import { Formik, Form } from 'formik'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import { _, history } from 'helpers'

import { useStyles } from './SearchInput.styles'


const validate = values => {
    let errors = {}

    if (!values.term.trim() || values.term.trim() === '') {
        errors.term = true
    }
    return errors
}

const submit = ({ term }, { setSubmitting }) => {
    setSubmitting(false)
    history.push(`/search/name/${term.toLowerCase()}`)
}

const SearchInput = props => {
    const classes = useStyles()
    return (
        <Formik
            initialValues={{ term: '' }}
            validate={validate}
            onSubmit={submit}
        >
            {props => {
                const {
                    values,
                    handleChange,
                    handleBlur } = props
                return (
                    <Form>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                value={values.term}
                                name="term"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ 'aria-label': 'Recherche' }}
                                placeholder={_('app.search')}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SearchInput
