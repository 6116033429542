import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { _, authenticated, history, browserUrls } from 'helpers'
import { authActions, alertActions } from 'modules'

import { useStyles } from './ForgotPassword.styles'
import { validate } from './ForgotPassword.helpers'

const ForgotPasswordForm = ({ handleSubmit }) => {
    const classes = useStyles()

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {_('auth.forgotPasswordTitle')}
                </Typography>

                <Formik
                    initialValues={{ email: '' }}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props

                        const disabled = isSubmitting

                        return (
                            <Form className={classes.form}>
                                <TextField
                                    variant="filled"
                                    type="text"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={_('field.email')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={errors.email && touched.email ? errors.email : undefined }
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={disabled}
                                >
                                    {_('auth.forgotPasswordButton')}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link className={classes.link} to="/login">
                                            {_('auth.loginButton')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Container>
    )
}


class ForgotPassword extends React.Component {

    componentWillMount(){
        if (authenticated()) {
            this.props.errorAlert(_('alert.alreadyLogged'))
            history.push('/')
        }
    }

    handleSubmit = ({ email }, { setSubmitting }) => {
        setSubmitting(true)
        if (email) {
            this.props.sendForgotPasswordMail(email, () => {    
                setSubmitting(false)
                history.push(browserUrls.login)
            })
        }
    }

    render() {
        return <ForgotPasswordForm handleSubmit={this.handleSubmit} />
    }
}

const mapDispatchToProps = {
    sendForgotPasswordMail: authActions.sendForgotPasswordMail,
    errorAlert: alertActions.error,
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
