import { eventService, eventConstants } from 'modules'

export const eventActions = {
    list,
    show,
}

function list(filters = undefined) {
    return dispatch => {
        dispatch(request(filters))
        eventService.list(filters).then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(filters) {
        return { type: eventConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: eventConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: eventConstants.LIST_FAILURE, error }
    }
}

function show(id) {
    return dispatch => {
        dispatch(request(id))
        eventService.show(id).then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(id) {
        return { type: eventConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: eventConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: eventConstants.SHOW_FAILURE, error }
    }
}

