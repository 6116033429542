import config from 'config/index'
import { Lang } from 'helpers'

export const browserUrls = {
    full: (url, lang = Lang.getLang()) => config.appUrl + url,
    // full: (url, lang = Lang.getLang()) => config.appUrl + '/' + lang + url,
    home: '/',
    login: '/login',
    logout: '/logout',
    error: {
        notfound: '/404',
    },
    posts: {
        group: '/posts',
        listing: '/posts',
        single: post => `/posts/${post}`,
    },
    events: {
        group: '/events',
        listing: '/events',
        single: event => `/events/${event}`,
    },
}

export const apiUrls = {
    guides: {
        group: 'guides',
        listing: 'guides',
        single: guide => `guides/${guide}`,
    },
    expeditions: {
        group: 'expeditions',
        listing: 'expeditions',
        single: (id, slug, date) =>
            `expeditions/${id}/${slug}${date ? `/${date}` : ''}`,
    },
    auth: {
        group: 'auth',
        user: 'auth/user',
        login: 'auth/login',
        logout: 'auth/logout',
        refresh: 'auth/refresh',
        email: status => `auth/email/${status}`,
        password: {
            forgot: 'auth/password/forgot',
            reset: token => `auth/password/reset/${token}`,
        },
        register: 'auth/register',
    },
}
