import { categoryService, categoryConstants } from 'modules'

export const categoryActions = {
    list,
}

function list() {
    return dispatch => {
        dispatch(request())
        categoryService.list().then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: categoryConstants.LIST_PENDING }
    }

    function success(payload) {
        return { type: categoryConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: categoryConstants.LIST_FAILURE, error }
    }
}
