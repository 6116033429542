// import { initSentry } from 'sentry'
import {
    _,
    browserUrls,
    history } from 'helpers'

import {
    alertActions,
    authConstants,
    authService } from 'modules'

export const authActions = {
    login,
    logout,
    refresh,
    register,
    sendForgotPasswordMail,
    sendConfirmationEmail,
    resetPassword,
}

function login(username, password, callback = () => null) {
    return dispatch => {
        dispatch(request({ username }))

        authService.login(username, password).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.expiration(data))
                history.push(browserUrls.home)
                callback()
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                callback()
            }
        )
    }

    function request(data) {
        return { type: authConstants.LOGIN_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGIN_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.LOGIN_FAILURE, error }
    }
}

function refresh() {
    return dispatch => {
        dispatch(request())

        authService.refresh().then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }

    function request() {
        return { type: authConstants.REFRESH_PENDING }
    }

    function success(payload) {
        return { type: authConstants.REFRESH_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.REFRESH_FAILURE, error }
    }
}

function logout() {
    authService.logout()
    // initSentry(false)
    return { type: authConstants.LOGOUT }
}

function register(user, callback = () => null) {
    return dispatch => {
        dispatch(request(user))

        authService.register(user).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(_('alert.registerSuccess')))
                callback(data)
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }

    function request(data) {
        return { type: authConstants.REGISTER_PENDING, data }
    }
    function success(payload) {
        return { type: authConstants.REGISTER_SUCCESS, payload }
    }
    function failure(error) {
        return { type: authConstants.REGISTER_FAILURE, error }
    }
}

function sendForgotPasswordMail(email, callback = () => null) {
    return dispatch => {
        dispatch(request(email))

        authService.sendForgotPasswordMail(email).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                callback()
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                callback()
            }
        )
    }

    function request(data) {
        return { type: authConstants.SEND_PASSWORD_MAIL_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.SEND_PASSWORD_MAIL_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.SEND_PASSWORD_MAIL_FAILURE, error }
    }
}

function sendConfirmationEmail(email, callback = () => null) {
    return dispatch => {
        dispatch(request({ email }))

        authService.sendConfirmationEmail(email).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                callback()
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                callback()
            }
        )
    }

    function request(data) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_PENDING, data }
    }
    function success(payload) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_SUCCESS, payload }
    }
    function failure(error) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_FAILURE, error }
    }
}

function resetPassword(data, callback = () => null) {
    return dispatch => {
        dispatch(request(data))

        authService.resetPassword(data).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                callback()
                history.push(browserUrls.login)
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                callback()
            }
        )
    }

    function request(data) {
        return { type: authConstants.RESET_PASSWORD_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.RESET_PASSWORD_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.RESET_PASSWORD_FAILURE, error }
    }
}

