import values from 'lodash/values'

export const getCategoryBySlug = (categories, slug) => {
    const result = values(categories).filter(category => category.slug === slug)
    return result && result[0] ? result[0] : false
}

export const getCategoryById = (categories, id) => {
    const result = values(categories).filter(category => category.id === id)
    return result && result[0] ? result[0] : false
}
