import { authConstants } from 'modules' // _constants

// initialize state with locally stored user if logged in
const storedAuth = JSON.parse(localStorage.getItem('auth'))
const initialState = storedAuth ? { ...storedAuth, loggedIn: true } : {}

export function auth(state = initialState, { type, payload }) {
    switch (type) {
        case authConstants.LOGIN_PENDING:
            return {
                loggingIn: true,
                token: undefined,
                user: undefined,
            }
        case authConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                token: payload.token,
                user: payload.user,
            }
        case authConstants.LOGIN_FAILURE:
            return {}
        case authConstants.LOGOUT:
            return {}
        default:
            return state
    }
}
