import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { authActions } from 'modules' 
import { _ } from 'helpers'

import { useStyles } from './Login.styles'
import { validate } from './Login.helpers'
import logo from 'images/logo-white.png'

const LoginForm = ({ handleSubmit }) => {
    const classes = useStyles()

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <div className={classes.paper}>

                <div className={classes.header}>
                    <img className={classes.logo} src={logo} alt={_('app.name')} />
                </div>

                <Typography component="h1" variant="h5">
                    {_('auth.loginTitle')}
                </Typography>

                <Formik
                    initialValues={{ email: '', password: '', }}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props

                        const disabled = isSubmitting

                        return (
                            <Form className={classes.form}>
                                <TextField
                                    variant="filled"
                                    type="email"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={_('field.email')}
                                    name="email"
                                    autoFocus
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={errors.email && touched.email ? errors.email : undefined }
                                />

                                <TextField
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={_('field.password')}
                                    type="password"
                                    id="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password}
                                    helperText={errors.password && touched.password ? errors.password : undefined }
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={disabled}
                                >
                                    {_('auth.loginButton')}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link className={classes.link} to="/password/forgot">
                                            {_('auth.forgotPasswordTitle')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Container>
    )
}


class Login extends React.Component {

    componentWillMount(){
        this.props.logout()
    }

    handleSubmit = ({ email, password }, { setSubmitting }) => {
        setSubmitting(true)
        if (email && password) {
            this.props.login(email, password, () => setSubmitting(false))
        }
    }

    render() {
        return <LoginForm handleSubmit={this.handleSubmit} />
    }
}

const mapDispatchToProps = {
    logout: authActions.logout,
    login: authActions.login,
}

export default connect(null, mapDispatchToProps)(Login)
