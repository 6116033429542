import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { _ } from 'helpers'
import { authActions, alertActions } from 'modules'

import { useStyles } from './ResetPassword.styles'
import { validate } from './ResetPassword.helpers'

const ResetPasswordForm = ({ handleSubmit, token, email }) => {
    const classes = useStyles()

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {_('auth.resetPasswordTitle')}
                </Typography>

                <Formik
                    initialValues={{ password: '', password_confirmation: '', token, email }}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props

                        const disabled = isSubmitting

                        return (
                            <Form className={classes.form}>

                                <input
                                    type="hidden"
                                    name="token"
                                    value={values.token}
                                />

                                <input
                                    type="hidden"
                                    name="email"
                                    value={values.email}
                                />

                                <TextField
                                    variant="filled"
                                    type="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label={_('field.newPassword')}
                                    name="password"
                                    autoComplete="password"
                                    autoFocus
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password}
                                    helperText={errors.password && touched.password ? errors.password : undefined }
                                />

                                <TextField
                                    variant="filled"
                                    type="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password_confirmation"
                                    label={_('field.confirmPassword')}
                                    name="password_confirmation"
                                    autoComplete="password_confirmation"
                                    value={values.password_confirmation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password_confirmation && touched.password_confirmation}
                                    helperText={errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : undefined }
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={disabled}
                                >
                                    {_('auth.resetPasswordButton')}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link className={classes.link} to="/login">
                                            {_('auth.loginButton')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Container>
    )
}


class ResetPassword extends React.Component {
    handleSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        this.props.resetPassword(data, () => setSubmitting(false))
    }

    render() {
        return <ResetPasswordForm handleSubmit={this.handleSubmit} token={this.props.match.params.token} email={this.props.match.params.email} />
    }
}

const mapDispatchToProps = {
    resetPassword: authActions.resetPassword,
    errorAlert: alertActions.error,
}

export default connect(null, mapDispatchToProps)(ResetPassword)
