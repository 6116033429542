export const eventConstants = {
    LIST_PENDING: 'EVENT/LIST_PENDING',
    LIST_SUCCESS: 'EVENT/LIST_SUCCESS',
    LIST_FAILURE: 'EVENT/LIST_FAILURE',

    SHOW_PENDING: 'EVENT/SHOW_PENDING',
    SHOW_SUCCESS: 'EVENT/SHOW_SUCCESS',
    SHOW_FAILURE: 'EVENT/SHOW_FAILURE',
    
    CREATE_PENDING: 'EVENT/CREATE_PENDING',
    CREATE_SUCCESS: 'EVENT/CREATE_SUCCESS',
    CREATE_FAILURE: 'EVENT/CREATE_FAILURE',
    
    UPDATE_PENDING: 'EVENT/UPDATE_PENDING',
    UPDATE_SUCCESS: 'EVENT/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'EVENT/UPDATE_FAILURE',

    DELETE_PENDING: 'EVENT/DELETE_PENDING',
    DELETE_SUCCESS: 'EVENT/DELETE_SUCCESS',
    DELETE_FAILURE: 'EVENT/DELETE_FAILURE',
}
