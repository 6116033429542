import mapKeys from 'lodash/mapKeys'
import { optionConstants } from './option.constants' // _constants

const storedData = JSON.parse(localStorage.getItem('options'))
const initialState = storedData ? { ...mapKeys(storedData, 'id') } : {}

// all state changes manipulations happen here
export const option = function(state = initialState, { type, payload }) {
    switch (type) {
        case optionConstants.LIST_SUCCESS:
            return mapKeys(payload.data, 'id')
        default:
            return state
    }
}
