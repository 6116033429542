import React from 'react'
import { connect } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import DateRangeIcon from '@material-ui/icons/DateRange'

import { _ } from 'helpers'
import { authActions, alertActions } from 'modules'
import Pageheader from 'components/Pageheader'
import { ButtonList, Button } from 'components/Button'
import { displayHumanFutureDate } from 'utils'


class Account extends React.Component {
    state = {
        disabled: false,
    }

    render() {
        const { sendForgotPasswordMail, user } = this.props
        const date = user.end ? displayHumanFutureDate(user.end) : false
        return (
            <>
            <Pageheader title={_('app.account')} smalltitle={_('app.viewAccount')}/>
                <List>
                    <ListItem>
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                        <ListItemText primary={user.name} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><EmailIcon /></ListItemIcon>
                        <ListItemText primary={user.email} />
                    </ListItem>
                    {date && <ListItem>
                        <ListItemIcon><DateRangeIcon /></ListItemIcon>
                        <ListItemText primary={ _('app.expiration', { date })} />
                    </ListItem>}
                </List>
                <ButtonList>
                    <Button
                        label={_('auth.requestPasswordReset')}
                        color="secondary"
                        disabled={this.state.disabled}
                        onClick={() => {
                            sendForgotPasswordMail(user.email)
                            this.setState({ disabled: true })
                        }}
                    />
                </ButtonList>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state
    return {
        user: auth.user,
    }
}

const mapDispatchToProps = {
    sendForgotPasswordMail: authActions.sendForgotPasswordMail,
    errorAlert: alertActions.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
