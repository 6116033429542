import { httpClient, save } from 'helpers'

export const postService = {
    list,
    show,
    create,
    update,
    destroy,
}

const resource = 'post'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''), {}, [save('posts')])
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(post) {
    const data = JSON.stringify(post)
    return httpClient.POST(resource, { data })
}

function update(post) {
    const data = JSON.stringify(post)
    return httpClient.PUT(`${resource}/${post.id}`, { data })
}

function destroy(post) {
    return httpClient.DELETE(`${resource}/${post.id}`)
}
