import app from './app'
import auth from './auth'
import date from './date'
import field from './field'
import alert from './alert'
import common from './common'
import dashboard from './dashboard'
import validation from './validation'

export default {
    translation: {
        app,
        alert,
        auth,
        common,
        dashboard,
        date,
        field,
        validation,
    },
}
