import React from 'react'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import TextsmsIcon from '@material-ui/icons/Textsms'
import LaunchIcon from '@material-ui/icons/Launch'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { _, history, authenticated } from 'helpers'
import { formatPhone } from 'utils'
import { getOptionByName } from 'modules'
import Button from 'components/Button'
import SearchInput from 'components/SearchInput'

import { useStyles } from './Topbar.styles'
import { ElevationScroll } from './Topbar.helpers'

const ListItemLink = props => <ListItem button component="a" {...props} />

const Topbar = ({ user, options, ...props }) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)

    const basePhone = getOptionByName(options, 'phone')
    const phone = basePhone && 'tel:' + formatPhone(basePhone)
    const baseEmail = getOptionByName(options, 'email')
    const email = baseEmail && 'mailto:' + baseEmail
    const baseTexto = getOptionByName(options, 'texto')
    const texto = baseTexto && 'sms:' + formatPhone(baseTexto)
    const website = getOptionByName(options, 'website')

    const toggleMenu = value => event => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setOpen(value)
    }

    const Menu = () => (
        <Drawer
            classes={{ paper: classes.paper }}
            open={open}
            onClose={toggleMenu(false)}
        >
            <div
                role="presentation"
                className={classes.drawer}
                onClick={toggleMenu(false)}
                onKeyDown={toggleMenu(false)}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={toggleMenu(false)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>

                <Divider />

                {user && (<>
                    <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
                    <Typography className={classes.menutitle} noWrap>{user.name}</Typography>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => history.push('/account')}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText primary={_('app.account')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/login')}>
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary={_('dashboard.logOutButton')} />
                        </ListItem>
                    </List>
                    <Divider />
                </>)}

                <Typography className={classes.menutitle} noWrap>{_('app.contactus')}</Typography>
                <Divider />
                <List>
                    {phone && <ListItemLink href={phone}>
                        <ListItemIcon><PhoneIcon /></ListItemIcon>
                        <ListItemText primary={basePhone} />
                    </ListItemLink>}

                    {texto && <ListItemLink href={texto}>
                        <ListItemIcon><TextsmsIcon /></ListItemIcon>
                        <ListItemText primary={baseTexto} />
                    </ListItemLink>}

                    {email && <ListItemLink href={email}>
                        <ListItemIcon><EmailIcon /></ListItemIcon>
                        <ListItemText primary={baseEmail} />
                    </ListItemLink>}

                    {website && <ListItemLink target="_blank" href={website}>
                        <ListItemIcon><LaunchIcon /></ListItemIcon>
                        <ListItemText primary={website} />
                    </ListItemLink>}

                </List>
                    
            </div>
        </Drawer>
    )

    return (
        <div className={classes.root}>
            <ElevationScroll {...props}>
                <AppBar>
                    <Menu />
                    <Toolbar variant="dense">                        
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            onClick={toggleMenu(true)}
                            color="inherit"
                            aria-label="Ouvrir le menu"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography className={classes.title} noWrap>
                            {user && _('app.hello', { name: user.name })}
                        </Typography>
                        
                        {!user && <Button color="secondary" size="small" to="/login" label={_('auth.loginButton')} />}

                        {authenticated() && <SearchInput />}
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </div>
    )
}


const mapStateToProps = ({ auth, options }) => {
    return {
        user: auth.user,
        options,
    }
}

export default connect(mapStateToProps)(Topbar)
