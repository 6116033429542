import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'

export const getPostsByCategoryId = (posts, id) => {
    if (isEmpty(posts)) return null
    const result = values(posts).filter(post => post.categories.includes(id))
    return result
}

export const getPostsBySearch = (posts, value) => {
    if (isEmpty(posts)) return null
    const result = values(posts).filter(post => post.name.toLowerCase().includes(value))
    return result
}
