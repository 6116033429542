import mapKeys from 'lodash/mapKeys'
import { postConstants } from 'modules' // _constants

const storedData = JSON.parse(localStorage.getItem('posts'))
const initialState = storedData ? { ...mapKeys(storedData, 'id') } : {}

// all state changes manipulations happen here
export const post = function(state = initialState, { type, payload }) {
    switch (type) {
        case postConstants.LIST_SUCCESS:
            // applying state last prevents previously fetched single values from being overwritten
            return { ...mapKeys(payload.data, 'id'), ...state }

        case postConstants.SHOW_SUCCESS:
            // adds the item with id has key in the items list state
            // using an object as the items list allows to overwrite the item with a newly fetched version of itself
            // whereas using an array would only push a duplicate version
            return { ...state, [payload.data.id]: payload.data }

        case postConstants.CREATE_SUCCESS:
        case postConstants.UPDATE_SUCCESS:
            return {
                ...state,
                [payload.data.id]: payload.data,
            }

        case postConstants.DELETE_SUCCESS:
            // returns a copy of the state without the deleted item
            let newState = { ...state }
            delete newState[payload.id]
            return newState

        default:
            return state
    }
}
