import React from 'react'
import { connect } from 'react-redux'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import Grid from '@material-ui/core/Grid'

import Card from 'components/Card'
import Pageheader from 'components/Pageheader'
import { Button, ButtonList, BackButton } from 'components/Button'

import { _ } from 'helpers'
import { displayDate } from 'utils'
import {
    postActions,
    getCategoryBySlug,
    getPostsByCategoryId,
    getPostsBySearch,
} from 'modules'


const Posts = ({ posts }) => {
    if (!posts || posts.length === 0) return null

    return map(posts, (post, key) => {
        const url = '/post/' + post.slug
        const title = post.name
        const company = post.company ? post.company.name : false
        const address = post.company && post.company.address ? post.company.address : false
        let subtitle = company ? company : '-'
        if (address) subtitle += `, ${address}`
        const date = displayDate(post.updated_at)
        return (
            <Grid key={key} xs={12} md={6} item>
                <Card
                    key={key}
                    url={url}
                    subtitle={subtitle}
                    title={title}
                    date={date}
                />
            </Grid> 
        )
    })
}

class PostList extends React.Component {
    
    state = {
        type: undefined,
        selectedCategory: undefined,
        value: '',
    }

    // get values from url and call search when needed
    getRouteValues() {
        const { categories, match } = this.props
        const { type, value } = match.params
        if (
            type !== this.state.type ||
            value !== this.state.value
        ) {
            // if searching by category, try to load it from the store
            const selectedCategory = type === 'category' ? getCategoryBySlug(categories, value) : false
            this.setState({
                type,
                value,
                selectedCategory: selectedCategory ? selectedCategory: undefined,
            }, this.doSearch)
        }
    }

    renderTitle() {
        const { posts, selectedCategory, value } = this.props
        const subtitle = _('app.results', { count: posts.length })
        const title = selectedCategory ? selectedCategory.name : _('app.resultsFor', { value })
        return (
            <Pageheader title={title} subtitle={subtitle} />
        )
    }

    render() {
        const { posts, selectedCategory } = this.props

        return (
            <>
                {this.renderTitle()}
                <Grid container spacing={2} alignItems="stretch">
                    <Posts posts={posts} />
                </Grid>
                <ButtonList>
                    {selectedCategory && <BackButton />}
                    {!selectedCategory && <Button to="/" label={_('common.back')} color="secondary" />}
                </ButtonList>
            </>
        )
    }
}

function mapStateToProps(state, { match }) {
    const { posts, categories } = state

    const { type, value } = match.params

    // if searching by category, load it from the store
    const selectedCategory = type === 'category'
        ? getCategoryBySlug(categories, value)
        : false

    const filteredPosts = selectedCategory
        ? getPostsByCategoryId(posts, selectedCategory.id)
        : getPostsBySearch(posts, value)

    return {
        type,
        value,
        categories,
        selectedCategory,
        posts: sortBy((filteredPosts ? filteredPosts : posts), ['updated_at']).reverse()
    }
}

const mapDispatchToProps = {
    listPost: postActions.list,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostList)
