import { optionConstants, optionService } from 'modules'

export const optionActions = {
    list,
}

function list() {
    return dispatch => {
        dispatch(request())
        optionService.list().then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: optionConstants.LIST_PENDING }
    }

    function success(payload) {
        return { type: optionConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: optionConstants.LIST_FAILURE, error }
    }
}
