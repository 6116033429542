import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from 'app/Login'
import Account from 'app/Account'
import PostShow from 'app/PostShow'
import PostList from 'app/PostList'
import EventShow from 'app/EventShow'
import EventList from 'app/EventList'
import CategoryList from 'app/CategoryList'
import ForgotPassword from 'app/ForgotPassword'
import ResetPassword from 'app/ResetPassword'
import { PrivateRoute } from 'components/PrivateRoute'

import { authenticated } from 'helpers'

export const Routes = () => (
    <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/password/forgot" component={ForgotPassword} />
        <Route exact path="/password/reset/:token/:email" component={ResetPassword} />
        <PrivateRoute redirect exact path="/categories" component={CategoryList} />
        <PrivateRoute redirect exact path="/account" component={Account} />
        <PrivateRoute redirect exact path="/event" component={EventList} />
        <PrivateRoute redirect path="/post/:post" component={PostShow} />
        <PrivateRoute redirect path="/event/:event" component={EventShow} />
        <PrivateRoute redirect path="/search/:type/:value" component={PostList} />
        <Route component={() => <Redirect to={authenticated() ? '/categories' : '/login'} /> } />
    </Switch>
)

export default Routes
