export const authConstants = {
    REGISTER_PENDING: 'AUTH/REGISTER_PENDING',
    REGISTER_SUCCESS: 'AUTH/REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTH/REGISTER_FAILURE',

    LOGIN_PENDING: 'AUTH/LOGIN_PENDING',
    LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',

    REFRESH_PENDING: 'AUTH/REFRESH_PENDING',
    REFRESH_SUCCESS: 'AUTH/REFRESH_SUCCESS',
    REFRESH_FAILURE: 'AUTH/REFRESH_FAILURE',

    LOGOUT: 'AUTH/LOGOUT',
    
    SEND_PASSWORD_MAIL_PENDING: 'AUTH/SEND_PASSWORD_MAIL_PENDING',
    SEND_PASSWORD_MAIL_SUCCESS: 'AUTH/SEND_PASSWORD_MAIL_SUCCESS',
    SEND_PASSWORD_MAIL_FAILURE: 'AUTH/SEND_PASSWORD_MAIL_FAILURE',

    SEND_CONFIRMATION_MAIL_PENDING: 'AUTH/SEND_CONFIRMATION_MAIL_PENDING',
    SEND_CONFIRMATION_MAIL_SUCCESS: 'AUTH/SEND_CONFIRMATION_MAIL_SUCCESS',
    SEND_CONFIRMATION_MAIL_FAILURE: 'AUTH/SEND_CONFIRMATION_MAIL_FAILURE',

    RESET_PASSWORD_PENDING: 'AUTH/RESET_PASSWORD_PENDING',
    RESET_PASSWORD_SUCCESS: 'AUTH/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'AUTH/RESET_PASSWORD_FAILURE',
}
