export const categoryConstants = {
    LIST_PENDING: 'CATEGORY/LIST_PENDING',
    LIST_SUCCESS: 'CATEGORY/LIST_SUCCESS',
    LIST_FAILURE: 'CATEGORY/LIST_FAILURE',

    SHOW_PENDING: 'CATEGORY/SHOW_PENDING',
    SHOW_SUCCESS: 'CATEGORY/SHOW_SUCCESS',
    SHOW_FAILURE: 'CATEGORY/SHOW_FAILURE',
    
    CREATE_PENDING: 'CATEGORY/CREATE_PENDING',
    CREATE_SUCCESS: 'CATEGORY/CREATE_SUCCESS',
    CREATE_FAILURE: 'CATEGORY/CREATE_FAILURE',
    
    UPDATE_PENDING: 'CATEGORY/UPDATE_PENDING',
    UPDATE_SUCCESS: 'CATEGORY/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CATEGORY/UPDATE_FAILURE',

    DELETE_PENDING: 'CATEGORY/DELETE_PENDING',
    DELETE_SUCCESS: 'CATEGORY/DELETE_SUCCESS',
    DELETE_FAILURE: 'CATEGORY/DELETE_FAILURE',
}
