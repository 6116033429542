import React from 'react'
import { connect } from 'react-redux'
import Markdown from 'react-markdown'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { _ } from 'helpers'
import { displayDate } from 'utils'
import { eventActions } from 'modules'
import Pageheader from 'components/Pageheader'
import { ButtonList, BackButton } from 'components/Button'
import { MediaCard } from 'components/Card'

import { useStyles } from './EventShow.styles'

const Header = ({ event }) => {
    const classes = useStyles()
    if (!event) return null

    const title = event.name
    const smalltitle = event.place
        ? event.place
        : false
    const date = displayDate(event.updated_at)
    const subtitle = `${date} ${event.time ? `· ${event.time}` : ''}`

    return (
        <div className={classes.header}>
            <Pageheader
                title={title}
                smalltitle={smalltitle}
                subtitle={subtitle}
            />
            <hr className={classes.hr} />
        </div>
    )
}

const Text = ({ event }) => {
    const classes = useStyles()
    if (!event) return null
    
    return  (
        <>
            {event.text &&
                <>
                    <Typography className={classes.title}>{_('app.description')}</Typography>
                    <Markdown source={event.text} />
                </>
            }
            {event.text2 &&
                <>
                    <Typography className={classes.subtitle}>{_('app.contactinfo')}</Typography>
                    <Markdown source={event.text2} />
                </>
            }
        </>
    )
}



const Gallery = ({ event }) => {
    const classes = useStyles()
    if (!event || !event.medias || event.medias.length === 0) return null
    
    return  (
        <>
            <hr className={classes.hr} />
            <Grid container spacing={2}>
                {event.medias.map((image, key) => (
                    <Grid key={key} xs={12} sm={6} md={4} item>
                        <MediaCard
                            url={image.url}
                            title={image.name}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

class EventShow extends React.Component {
    componentDidMount() {
        this.props.showEvent(this.props.event_id)
    }

    render() {
        const { event } = this.props
        if (!event) return null
        return (
            <div className="eventpage">
                <Header event={event} />
                <Text event={event} />
                <Gallery event={event} />
                <ButtonList>
                    <BackButton />
                </ButtonList>
            </div>
        )
    }
}

function mapStateToProps(state, { match }) {
    const { events } = state
    const { event } = match.params
    const event_id = event.split('-')[0]
    return {
        event_id,
        event: events[event_id],
    }
}

const mapDispatchToProps = {
    showEvent: eventActions.show,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventShow)
