import { removeEmpty, required, email } from 'utils'

export const validate = values => {
    const errors = {
        email: email(values.email),
        password: required(values.password)
    }
    
    return removeEmpty(errors)
}
