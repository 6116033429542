import { postService, postConstants } from 'modules'

export const postActions = {
    list,
    show,
}

function list(filters = undefined) {
    return dispatch => {
        dispatch(request(filters))
        postService.list(filters).then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(filters) {
        return { type: postConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: postConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: postConstants.LIST_FAILURE, error }
    }
}

function show(id) {
    return dispatch => {
        dispatch(request(id))
        postService.show(id).then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(id) {
        return { type: postConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: postConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: postConstants.SHOW_FAILURE, error }
    }
}

