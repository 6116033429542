import {
    apiUrls,
    httpClient,
    handleAuth } from 'helpers'

export const authService = {
    login,
    logout,
    refresh,
    register,
    sendForgotPasswordMail,
    sendConfirmationEmail,
    resetPassword,
}


function login(email, password) {
    const data = JSON.stringify({ email, password })
    return httpClient.POST(apiUrls.auth.login, { data }, [ handleAuth ])
}

function refresh() {
    return httpClient.POST(apiUrls.auth.refresh, { }, [ handleAuth ])
}

// remove user from local storage to log user out
function logout() {
    console.warn('Logging out...')
    localStorage.removeItem('auth')
    localStorage.removeItem('posts')
    localStorage.removeItem('events')
}

function register(user) {
    const data = JSON.stringify(user)
    return httpClient.POST(apiUrls.auth.register, { data }, [ handleAuth ])
}

function sendForgotPasswordMail(email) {
    const data = JSON.stringify({ email })
    return httpClient.POST('auth/password/forgot', { data })
}

function sendConfirmationEmail(email) {
    const data = JSON.stringify(email)
    return httpClient.POST('auth/email/confirm/resend', { data })
}

function resetPassword(data) {
    data = JSON.stringify(data)
    return httpClient.POST('auth/password/reset', { data })
}
