import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        background: 'rgba(66, 66, 66, 0.5)',
        height: '100%',
    },
    action: {
        height: '100%',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: 14,
    },
    subheader: {
        fontSize: 12,
    },
    date: {
        fontSize: 12,
        opacity: 0.8,
    },
    media: {
        // height: 140,
        paddingBottom: '55%',
    },
    fullimage: {
        width: 'auto',
        height: 'auto',
        maxWidth: '100vw',
        maxHeight: 'calc(100vh - 55px)',
    }
}))

// inverted
export const useInvertedStyles = makeStyles(theme => ({
    ...useStyles,
    root: {
        ...useStyles.root,
        background: theme.palette.secondary.main + '88', // opacity
        color: 'white',
    },
    title: {
        ...useStyles.title,
        color: 'white',
    },
    subheader: {
        ...useStyles.subheader,
        color: 'white',
    },
}))
